import { Suspense, useEffect, useState, lazy } from 'react';
import createStyles from '@guestyci/foundation/createStyles';
import Spinner from '@guestyci/foundation/Spinner';
import { useOktaAuth } from '@okta/okta-react';
import { getSecureAuthCookie } from '../../login/helpers';
import Col from '@guestyci/foundation/Layout/Col';
import Row from '@guestyci/foundation/Layout/Row';

import GuestyLogo from './guesty-logo-new1.svg';
import { utils } from 'utils';
import { getEnv } from 'utils/env';
import TextField from '@guestyci/foundation/TextField';

const useStyles = createStyles(
  () => ({
    iframe: {
      minHeight: '100vh',
      flex: 1,
      flexGrow: 1,
      border: 'none',
    },
    iframe2: {
      paddingLeft: '5px',
      minHeight: '100vh',
      width: '100%',
      border: 'none',
    },
    leftContainer: {
      flex: '2',
      flexGrow: 2,
      height: '100%',
    },
    spinnerContainer: {
      marginTop: '115px',
      padding: '15px',
    },
    appLogoContainer: {
      paddingTop: '5vh',
      paddingLeft: '4vw',
      marginBottom: '5vh',
      '& img': {
        width: '127.71px',
      },
    },
  }),
  { name: 'GoogleLoginHandler' }
);

const GoogleLoginHandler = () => {
  const classes = useStyles();
  const { oktaAuth: authClient } = useOktaAuth();

  useEffect(() => {
    const initAuthentication = async () => {
      try {
        const session = await authClient.session.get();
        window.localStorage.removeItem('token');
        const { tokens } = await authClient.token.getWithoutPrompt();
        authClient.tokenManager.setTokens(tokens);
        await getSecureAuthCookie();
        console.log('Loading - Finished saving tokens.');
        if (tokens?.accessToken) {
          utils.saveUserData(tokens?.accessToken?.accessToken);
        }

        const currentUrl = new URL(window.location.href);
        const queryParams = currentUrl.search;
        const newUrl = queryParams ? `/${queryParams}` : '/';
        window.location.replace(newUrl);
      } catch (e) {
        console.error('Google handler failed with error', e);
      }
    };

    initAuthentication();
  }, [authClient]);

  return (
    <Row fullWidth className={classes.root} spacing={0}>
      <Col
        fullWidth
        align="start"
        spacing={100}
        className={classes.leftContainer}
      >
        <Row fullWidth className={classes.appLogoContainer}>
          <img src={GuestyLogo} alt="Guesty Lite Logo" />
        </Row>
        <Col
          className={classes.spinnerContainer}
          fullWidth
          justify="center"
          align="center"
        >
          <Spinner size={30} strokeWidth={2} />
          <TextField className="mt-2">{'Login in progress...'}</TextField>
        </Col>
      </Col>
      <Row fullWidth className={classes.iframe}>
        <iframe
          className={classes.iframe2}
          src="https://www.guesty.com/promo-iframe-glite-signup/"
          title="Guesty Marketing Material2"
        />
      </Row>
    </Row>
  );
};

export default GoogleLoginHandler;
